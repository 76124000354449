import type { AppProps } from 'next/app';
import { appWithTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import Head from 'next/head';
import { MyContext, IContextProps } from '../config/context-manager';
import '../styles/base.scss';

const App = ({ Component, pageProps }: AppProps) => {
  const router = useRouter();
  const { locale } = router;
  const value = { locale };

  return (
    <MyContext.Provider value={value as IContextProps}>
      <Head>
        <title>学习卡大礼包</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0" />
      </Head>
      <Component {...pageProps} />
    </MyContext.Provider>
  );
};

export default appWithTranslation(App);
